<template>
  <div class="main">
    <Header/>
    <div class="d-flex align-items-stretch h-100" style="ovflow:hidden">
      <section style="width:20%;min-width: 200px;max-width:300px;">
        <el-menu
          :default-active="routerName"
          class="h-100"
          router
          @open="handleOpen"
          @close="handleClose">
          <el-menu-item :index="routeIndex[0]">
            <i class="el-icon-menu"></i>
            <span slot="title">系统消息</span>
          </el-menu-item>
          <el-menu-item :index="routeIndex[1]">
            <i class="el-icon-s-cooperation"></i>
            <span slot="title">任务消息</span>
          </el-menu-item>
          <el-submenu index="/tips-admin/recruit">
            <template slot="title">
              <i class="el-icon-s-promotion"></i>
              招募公告
            </template>
            <el-menu-item :index="routeIndex[2]">线上公告</el-menu-item>
            <el-menu-item :index="routeIndex[3]">历史公告</el-menu-item>
          </el-submenu>
          <el-submenu index="/tips-admin/play-fanhan">
            <template slot="title"><icon-font  class="iconfont mr-2" name="#fanhan-wanzhuanfanhan" :width="18" :height="18" color="#909399"></icon-font><span>玩转泛函</span></template>
            <el-menu-item :index="routeIndex[4]">新手引导</el-menu-item>
            <el-menu-item :index="routeIndex[5]">帮助文档</el-menu-item>
            <el-menu-item :index="routeIndex[6]">关于泛函</el-menu-item>
          </el-submenu>
          <el-submenu index="/tips-admin/announce/team">
            <template slot="title"><icon-font  class="iconfont mr-2" name="#fanhan-weibiaoti-1" :width="18" :height="18" color="#909399"></icon-font>泛团公告</template>
            <el-menu-item :index="routeIndex[7]">在线公告</el-menu-item>
            <el-menu-item :index="routeIndex[8]">历史公告</el-menu-item>
          </el-submenu>
          <el-submenu index="/tips-admin/announce/fahan">
            <template slot="title"><icon-font  class="iconfont mr-2" name="#fanhan-fanhangonggao" :width="18" :height="18" color="#909399"></icon-font>泛函公告</template>
            <el-menu-item :index="routeIndex[9]">在线公告</el-menu-item>
            <el-menu-item :index="routeIndex[10]">历史公告</el-menu-item>
          </el-submenu>
          <!-- <el-submenu index="/tips-admin/banner">
            <template slot="title"> <i class="el-icon-picture"></i>banner</template>
            <el-menu-item :index="routeIndex[7]">首页</el-menu-item>
          </el-submenu> -->
        </el-menu>
      </section>
      <section class="pl-5 pr-5" style="flex:1;overflow:auto;">
        <router-view v-if="isRouterAlive" :key="$route.fullPath"/>
      </section>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  components: {
    Header
  },
  data () {
    return {
      isRouterAlive: true,
      stewardFlag: false,
      routeIndex: [
        '/tips-admin/system',
        '/tips-admin/task',
        '/tips-admin/recruit/main',
        '/tips-admin/recruit/history',
        '/tips-admin/play-fanhan/new-guidance',
        '/tips-admin/play-fanhan/help-document',
        '/tips-admin/play-fanhan/about-fanhan',
        '/tips-admin/announce/team/online',
        '/tips-admin/announce/team/history',
        '/tips-admin/announce/fahan/online',
        '/tips-admin/announce/fahan/history',
        '/tips-admin/banner/main'
      ]
    }
  },
  watch: {
    $route (newV, oldV) {
      this.stewardColor()
    }
  },
  mounted: function () {
    this.$nextTick(function () {
    })
  },
  computed: {
    routerName () {
      const filterStr = this.routeIndex.filter(v => this.$route.path.indexOf(v) !== -1)
      return filterStr.length !== 0 ? filterStr[0] : this.$route.path
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 导航事件
    handleOpen (key, keyPath) {
      // console.log(key, keyPath)
    },
    // 导航事件
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    stewardColor () {
      if (this.$route.path.indexOf('steward') !== -1) {
        this.stewardFlag = true
      } else {
        this.stewardFlag = false
      }
    }
  }
}
</script>

<style>
body {
  padding-top: 3.5rem;
}
a:hover {
  text-decoration: none;
}
.tool-box {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.btn-action {
  padding-top: 1rem;
}
</style>
